
.FooterStripe {
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  background-color: black;
  margin: auto;
}

@media only screen and (min-width: 601px) {


  .App {
    width: 601px;
    margin: auto;
    background-color: #393536;
  }



}

@media only screen and (max-width: 600px) {
  .App-logo {
    width: 100vw;
  }
  .App {
    width: 100vw;
  }

  #whereToTwoStep {
    float: right;
  }

}



.App-link {
  color: #61dafb;
}

.content {
  padding: 10px;
}